@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  max-width: 100;
  letter-spacing: 0.01em;
  font-family: "Noto Sans", sans-serif;
}

/* To remove click event on touch screens*/
a,
* {
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nunito", sans-serif;
}

/* To keep the UI from breaking */
.container {
  max-width: 1200px;
  padding: 0 2rem;
  position: relative;
  margin: auto;
}

::-ms-reveal {
  display: none;
}

/* custom shadow */
.shadows {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

img {
  pointer-events: none;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* Multi Select */
.react-select__control {
  /* padding: 0.5rem 0; */
  background: transparent !important;
  border: 0px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.react-select__menu {
  padding: 0.5rem;
  background: #eee !important;
}

@media (min-width: 1536px) {
  .container {
    max-width: 1420px;
  }
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #909090;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #585858;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.apexcharts-menu.apexcharts-menu-open {
  width: max-content !important;
}

.shadow-links {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Pagination */

.pagination a,
.prevNext a {
  padding: 10px 15px !important;
}

/* PBI css */
iframe::-webkit-scrollbar,
.visualContainerHost::-webkit-scrollbar,
.visualContainerOutOfFocus::-webkit-scrollbar {
  display: none !important;
}

video,
iframe {
  clip-path: inset(1px 1px);
}

img {
  pointer-events: none;
}

/* Menu */
.menu {
  max-width: 4rem;
  overflow-y: hidden;
  overflow-x: auto;
  transition: max-width 500ms ease-in-out;
}

.menu.active {
  max-width: 2000px; /* Adjust the max-height to your needs */
  transition: max-width 500ms ease-in-out;
}

/* Open Box */
.box {
  max-width: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 500ms ease-in-out;
}

.box.active {
  max-width: 1000px;
  max-height: 1000px;
  overflow: auto;
  transition: all 500ms ease-in-out;
}

/* Max height for react select */
.inventory__menu .inventory__menu-list {
  max-height: 130px;
}

/* Change autofill background */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.apexcharts-toolbar {
  z-index: 0 !important;
}

/* radio */
input[type="radio"] {
  accent-color: rgb(89, 89, 89);
}

/* range */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgb(89, 89, 89) !important;
}

/* Slider animation for non navigating page tabs */
@keyframes slideBackground {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* Add this class to the active tab */
.animate-slide {
  animation: slideBackground 1s ease infinite alternate;
}
